import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { useTheme } from 'emotion-theming'

import Card from './Card'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {
  laptopContainerStyle,
  mobileContainerStyle
} from '../../components/Common'
import { H1M, H2, P, pStyle } from '../../styleElements'
import useEventsPage from '../../queries/useEventsPage'
import mq from '../../utils/mediaQuery'

const EventsPage = () => {
  const nodes = useEventsPage()
  const theme = useTheme()

  const EVENT_COLLECTION = [
    {
      name: 'Cocoaheads Taipei',
      img: nodes.eventsCocoaheads,
      desc: 'iOS Developer meetup',
      link: 'https://www.facebook.com/groups/cocoaheads.taipei/',
      color: theme.color.secondary5
    },
    {
      name: 'Android Taipei',
      img: nodes.evetnsAndroidtaipei,
      desc: 'Android Developer meetup',
      link: 'https://www.facebook.com/groups/AndroidTaipei/',
      color: theme.color.secondary6
    },
    {
      name: 'UI/UX Taipei',
      img: nodes.eventsUiuxtaipei,
      desc: 'UI UX Designer meetup Designers’ Night',
      link: 'https://www.facebook.com/groups/TaiwanUIUXDesigners/',
      color: theme.color.secondary7
    },
    {
      name: 'Taiwan VR',
      img: nodes.eventsTaiwanvr,
      desc: 'Taiwan Virtual Reality meetup',
      link: 'https://www.facebook.com/groups/taiwan3d/',
      color: theme.color.black
    }
  ]

  return (
    <Layout>
      <SEO title="Events" />
      <UpArea>
        <UpAreaTriangle>
          <Img
            fluid={nodes.evetnsGraybg}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </UpAreaTriangle>
      </UpArea>
      <RootArea>
        <HeaderWrapper>
          <HeaderTitleWrapper>
            <HeaderTitle>EVENTS</HeaderTitle>
            <HeaderSubTitle>Monthly Events</HeaderSubTitle>
          </HeaderTitleWrapper>
          <HeaderContent>
            We are holding monthly meetups in{' '}
            <HQLink to="/address">PicCollage Taipei HQ</HQLink> The following
            events are all FREE. Welcome to join and share :)
          </HeaderContent>
        </HeaderWrapper>
        <EventsWrapper>
          {EVENT_COLLECTION.map(event => (
            <Card key={event.name} {...event} />
          ))}
        </EventsWrapper>
      </RootArea>
    </Layout>
  )
}

const UpArea = styled.div`
  position: absolute;
  background-color: ${props => props.theme.color.primary1};
  width: 100%;
  height: 1000px;

  ${mq.md} {
    height: 650px;
  }
`

const UpAreaTriangle = styled.div`
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
`

const RootArea = styled.article`
  ${mobileContainerStyle}
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 100px;
  position: relative;

  ${mq.md} {
    padding-top: 170px;
  }
`

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  ${mq.md} {
    ${laptopContainerStyle}
    flex-direction: row;
    max-width: 980px;
    width: 80%;
    margin-bottom: 50px;
    position: relative;
  }
`

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.md} {
    flex-shrink: 0;
    padding-right: 70px;
  }
`

const HeaderTitle = styled(H1M)`
  color: ${props => props.theme.color.white};

  ${mq.md} {
    position: absolute;
    transform: rotate(90deg);
    left: -150px;
    top: -60px;
  }
`

const HeaderSubTitle = styled(H2)`
  color: ${props => props.theme.color.white};
  padding: 40px 0;

  ${mq.md} {
    padding: 0;
  }
`

const HeaderContent = styled(P)`
  color: ${props => props.theme.color.white};
  font-weight: ${props => props.theme.fontWeight.bold};

  ${mq.md} {
    padding-right: 100px;
  }
`

const HQLink = styled(Link)`
  ${pStyle}
  color: ${props => props.theme.color.white};
  text-decoration: underline;
  font-weight: ${props => props.theme.fontWeight.bold};
`

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.md} {
    ${laptopContainerStyle}
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
  }
`

export default EventsPage
