import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useEventsPage = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        evetnsAndroidtaipei: file(
          relativePath: { eq: "events_androidtaipei.png" }
        ) {
          ...FluidImage
        }
        eventsCocoaheads: file(relativePath: { eq: "events_cocoaheads.png" }) {
          ...FluidImage
        }
        evetnsGraybg: file(relativePath: { eq: "events_graybg.png" }) {
          ...FluidImage
        }
        eventsTaiwanvr: file(relativePath: { eq: "events_taiwanvr.png" }) {
          ...FluidImage
        }
        eventsUiuxtaipei: file(relativePath: { eq: "events_uiuxtaipei.png" }) {
          ...FluidImage
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useEventsPage
